import React, { useRef, useState } from "react";

import "./App.css";

function App() {
  const [audioStatus, changeAudioStatus] = useState(false);
  const myRef = useRef<any>();

  const startAudio = () => {
    myRef.current?.play();
    changeAudioStatus(true);
  };

  const pauseAudio = () => {
    console.log("here");
    myRef.current?.pause();
    changeAudioStatus(false);
  };

  return (
    <div className="flex items-center flex-col h-screen justify-center ">
      <div className="relative flex justify-center -mr-20">
        <img
          id="cuillere"
          src="/assets/cuillere.png"
          alt="casserole"
          className={`${
            audioStatus ? "rotate-[250deg]" : "rotate-[200deg]"
          } max-w-[250px] absolute right-[250px] `}
        />
        <audio ref={myRef} src="/assets/casserole.mp3" />
        <img
          src="/assets/casserole.png"
          alt="casserole"
          className="rotate-[75deg] max-w-md  "
        />
      </div>
      <button
        className="mt-20 border bg-black text-white px-10 py-5 uppercase cursor-pointer z-50 hover:bg-white border-2 border-black hover:text-black"
        onClick={audioStatus ? () => pauseAudio() : () => startAudio()}
      >
        {audioStatus ? "Arretez !" : "Frappez-moi !"}
      </button>
    </div>
  );
}

export default App;
